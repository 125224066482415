import React, {Component} from 'react';
import HeaderImageWrapper from "../modules/HeaderImageWrapper";

class ResumeSubmittalPage extends Component {

	constructor(props) {
		super(props);
		document.title = "Resume Submittal - Conexco Group, LLC";
	}

	render() {
		
		// input
	
		// display	
		return (
			<div>
				<HeaderImageWrapper className="resume"/>
				<h1>RESUME SUBMITTAL</h1>

				<p>ALL resumes submitted will be handled in strict confidence and will NOT leave this office without the sender's prior notification. Confidentiality is the hallmark of our firm and that aspect of our business will not be compromised.</p>

				<p>You may include a short note that describes your current availability, current or last compensation data, geographical preferences, preferred times to be contacted, etc. If you are not in a construction related industry, you will never hear from us. If you are in the construction industry and your background information/needs coincides with a current or future search assignment, we will discreetly contact you. It should also be understood, CONEXCO Group is a client-driven construction executive consulting & search firm. We do not find jobs for individuals, we find superior construction talent for our client companies.</p>

				<p>All resumes will be handled in strict confidence and shall be submitted in a Microsoft Word document to <a style={{display:"inline"}} href="mailto:dpc@conexcogroup.com">dpc@conexcogroup.com</a></p>
			</div>
		);
	}
}

export default ResumeSubmittalPage;