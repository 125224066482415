import React, {Component} from 'react';
import HeaderImageWrapper from "../modules/HeaderImageWrapper";

class ProcessPage extends Component {

	constructor(props) {
		super(props);
		document.title = "Process - Conexco Group, LLC";
	}

	render() {
		
		// input
	
		// display	
		return (
			<div>
				<HeaderImageWrapper className="process"/>
				<h1>Process</h1>
				<p>At CONEXCO Group, each of our assignments is customized to the needs of our client and candidate. After over 25 years of experience, our management has found that the fundamental evolution of our search process follows a consistent path to completion. Our search objective is to provide consultation, coaching and leadership for all interested parties. Our promise to the client is to represent their firm with integrity and professionalism. Our promise to every candidate is to provide counsel and professional guidance in understanding their personal/professional needs along with their aspirations while maintaining the strictest of confidence.</p>


				<p>Engaging Us</p>

				<p>CONEXCO Group offers flexible options which include:</p>

				<p>Retained Search Option</p>

				<p>This option is highly recommended when seeking to secure your most crucial construction management positions as well as for staffing levels where timing is a critical element. Confidential replacement assignments also fall into this category where special circumstances often exist.The retainer search option requires a small portion of the estimated fee, in advance, which is credited upon completion of the search and when a CONEXCO referral is hired. This method guarantees results defined by a predetermined search stategy in a predictable time frame. Extended candidate guarantees are also included in retained search work.</p>

				<p>Contingency Search Option</p>

				<p>The contingency search option is designed for firms that are not seeking a devoted effort on our part, however, desire to confidentially meet candidates because of a less critical need or for planning purposes. Many contingency searches evolve into retained searches as the need becomes more critical and/or more time sensitive. Contingency search work requires no payment in advance, only when our referral is hired.</p>

				<p>Whichever option is selected, the distinction of a consultative search firm such as CONEXCO Group is that we're not just recruiting the candidate but managing the process to completion. The end of the process is as critical as the beginning. We make sure the client hires the best possible candidate and our candidate accepts an offer he or she is excited about accepting. We work diligently to manage client and candidate expectations and openly communicate throughout our process.</p>

				<p>Contact CONEXCO Group to learn more or to begin your search. We exist to serve you.</p>
			</div>
		);
	}
}

export default ProcessPage;