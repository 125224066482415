import React, {Component} from 'react';
import ImageScrollerModule from "../modules/ImageScrollerModule";

class HomePage extends Component {

	constructor(props) {
		super(props);
		document.title = "Conexco Group, LLC";
	}

	render() {
		
		// input
	
		// display	
		return (
			<div>
				<ImageScrollerModule/>
				<h1>WELCOME TO CONEXCO GROUP</h1>
				<p>Partnering with CONEXCO Group is truly a collaborative experience. Conducting a search for your firm is not a responsibility that should be taken lightly. Our methods and time-proven process are based on a thorough understanding of the strategic, financial and operational issues affecting your organization today. We are a leading provider of management talent and executive leadership for the construction industry, exclusively, and have developed long lasting relationships by demonstrating professionalism, commitment, understanding, knowledge and persistence.</p>

				<p>With the construction community now facing many more challenges in attracting, developing and retaining talent, more companies trust CONEXCO Group than any other firm to deliver and secure the best construction talent available. This is a responsibility we take seriously and work every day to meet with integrity and results.</p>

				<p>Contact CONEXCO Group today and you’ll find we understand your business and speak your language.</p>
			</div>
		);
	}
}

export default HomePage;