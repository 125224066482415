import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LayoutModule from "./modules/LayoutModule";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import ProcessPage from "./pages/ProcessPage";
import ResumeSubmittalPage from "./pages/ResumeSubmittalPage";
import IndustryResourcesPage from "./pages/IndustryResourcesPage";
import ContactUsPage from "./pages/ContactUsPage";
import Error404Page from "./pages/Error404Page";

class App extends Component {
	render() {
		return (
			<div id="App">
				<Router>
					<LayoutModule>
						<Route exact path="/" component={HomePage}/>
						<Route exact path="/404" component={Error404Page}/>
						<Route exact path="/about" component={AboutUsPage}/>
						<Route exact path="/process" component={ProcessPage}/>
						<Route exact path="/resume_submittal" component={ResumeSubmittalPage}/>
						<Route exact path="/industry_resources" component={IndustryResourcesPage}/>
						<Route exact path="/contact_us" component={ContactUsPage}/>
					</LayoutModule>
				</Router>
			</div>
		);
	}
}

export default App;
