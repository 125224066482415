import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NavLinksModule from "./NavLinksModule";
import HeaderImageWrapper from "./HeaderImageWrapper";

const FooterWrapper = styled.div`
	color:White;
    padding:0 1em;
	font-size:0.8em;
	background:var(--primary-color);
    
    & > div {
		display:flex;
    	align-items: center;
		flex-flow:row nowrap;
		
		& > div {
			&:first-child {
				padding:1em 0;
				text-align:right;
				
				& > nav {
					& > a {
						display:inline-block;
						padding:0 0.5em;
						margin:0.25em 0;
						
						&:nth-last-child(n+2) {
							border-right:solid 1px var(--hover-color);
						}
						
						&:hover {
							color:var(--hover-color);
							text-decoration:underline;
						}
					}
				}
			}
			
			&:nth-child(2) {
				flex: 1;
				text-align: center;
				
				& > a > img {
					width:128px;
					height:66px;
					padding:1em;
				}
			}
			
			&:nth-child(3) {
				min-width:25em;
				padding:1em 0;
				span:first-child {
					margin-right:0.5em;
					padding-right:0.5em;
					display:inline-block;
					border-right:solid 1px;
				}
				a {
					color:inherit;
					text-decoration:none;
					
					&:hover {
						color:var(--hover-color);
						text-decoration:underline;
					}
				}
			}
		}
	}
	
	@media screen and (max-width:65em) {
	
		& > div {
			flex-flow:column nowrap;		
	
			& > div {
				&:first-child {
					text-align:center;
				}
				
			}
		}
	}
	
	@media screen and (max-width:37em) {
	
		& > div {
			flex-flow:column nowrap;		
	
			& > div {
				&:first-child {
					text-align:left;
					& > nav > a {
						display:block;
						padding-left:0;
						border-right:none !important;
					}
				}
				
				&:nth-child(3) {
    				min-width:0;
				}
				
			}
		}
	}
	
	@media screen and (max-width:23em) {
	
		& > div {		
	
			& > div {
				
				&:nth-child(3) {
				
					span {
						display:block;
						border-right:none !important;
					}
					
					& > div,
					& > address {
						margin-bottom:0.5em;
					}
				}
				
			}
		}
	}
`;

class FooterModule extends Component {

	render() {
		
		// input
	
		// display	
		return (
			<FooterWrapper>
				<div>
					<div>
						<NavLinksModule gotoTop={true}/>
						© 2019 CONEXCO Group, LLC. All Rights Reserved.
					</div>
					<div><Link to="/"><img src="/img/logo-sm.gif" alt="Conexco" /></Link></div>
					<div>
						<div>CONEXCO Group, LLC</div>
						<address>
							<span>6614 Clayton Rd. Suite 307</span>
							<span>St. Louis, MO 63117</span>
						</address>
						<div>
							<span>phone. <a href="tel:3148623777">314.862.3777</a></span>
							<span>fax. <a href="fax:3143710034">314.371.0034</a></span>
						</div>
					</div>
				</div>
				<HeaderImageWrapper className="preload"/>
			</FooterWrapper>
		);
	}
}

export default FooterModule;