import React, {Component} from 'react';
import HeaderImageWrapper from "../modules/HeaderImageWrapper";

class AboutUsPage extends Component {

	constructor(props) {
		super(props);
		document.title = "About Us - Conexco Group, LLC";
	}

	render() {
		
		// input
	
		// display	
		return (
			<div>
				<HeaderImageWrapper className="about"/>
				<h1>About Us</h1>
				<p>CONEXCO Group, LLC is a client driven, retainer-based construction executive consulting & professional recruiting firm that focuses on providing excellent counsel to industry executives. By serving the construction industry, exclusively, CONEXCO Group’s professionals have an intimate understanding of our client’s distinctive culture and goals. This enables us to produce construction professionals from a pool of talent often not discovered by the traditional methods of recruiting. </p>
				<p>Our philosophy at CONEXCO Group is to conduct each search assignment in partnership with our client and to act as an extension of their firm in order to ensure the candidates’ technical competencies as well as their cultural backgrounds complement the existing group. Our clients include general contractors; construction managers; design-builders; heavy & highway contractors; municipal contractors; mechanical contractors; electrical contractors; alternative energy firms; architectural & engineering firms; all industry specific organizations.</p>
			</div>
		);
	}
}

export default AboutUsPage;