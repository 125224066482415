import React, {Component} from 'react';

class Error404Page extends Component {

	render() {
		
		// input
	
		// display	
		return (
			<div>
				Requested page not found.
			</div>
		);
	}
}

export default Error404Page;