import React, {Component} from 'react';
import HeaderImageWrapper from "../modules/HeaderImageWrapper";

class IndustryResourcesPage extends Component {

	constructor(props) {
		super(props);
		document.title = "Industry Resources - Conexco Group, LLC";
	}

	render() {
		
		// input
	
		// display	
		return (
			<div>
				<HeaderImageWrapper className="resources"/>
				<h1>INDUSTRY RESOURCES</h1>
				<p>CONEXCO Group management attends and participates in construction industry trade shows and events - nationally. We are active participants and supporters in professional organizations that serve and benefit the construction industry. The links below represent a sampling of these organizations and are provided for your convenience.</p>


				<p>
					<a href="http://www.acec.org" target="blank">ACEC - American Consulting Engineers Council</a>
					ACEC is the largest national organization of engineers engaged in the practice of consulting engineering.
				</p>


				<p>
					<a href="http://www.abc.org" target="blank">ABC</a>
					ABC is a national trade association representing over 21,000 contractors, subcontractors, material suppliers and related firms across the country.
				</p>


				<p>
					<a href="http://www.agc.org" target="blank">AGC - Associated General Contractors</a>
					AGC is the nation's largest and oldest construction trade association established in 1918.
				</p>


				<p>
					<a href="http://www.aiaonline.com" target="blank">AIA - American Institute of Architects</a>
					AIA offers a number of services provided to professional architects.
				</p>


				<p>
					<a href="http://www.asaonline.com" target="blank">ASA - American Subcontractors Association</a>
					ASA is an association of professional contractors. Founded in 1966, ASA serves 6,500 firms through a nationwide network of state and local chapters.
				</p>


				<p>
					<a href="http://www.asce.org" target="blank">ASCE - American Society of Civil Engineers</a>
					ASCE represents more than 123,000 members of the civil engineering profession worldwide, and is America's oldest national engineering society founded in 1852.
				</p>


				<p>
					<a href="http://www.highwayengineers.org" target="blank">ASHE - American Society of Highway Engineers</a>
					ASHE supports education and innovation and promotes a safe, efficient highway system for continued mobility.
				</p>


				<p>
					<a href="http://www.asme.org" target="blank">ASME - American Society of Mechanical Engineers</a>
					ASME holds some 30 technical conferences and 200 professional development courses each year and sets many industrial and manufacturing standards.
				</p>


				<p>
					<a href="http://www.asse.org" target="blank">ASSE - American Society of Safety Engineers</a>
					ASSE is the world's oldest and largest professional safety organization founded in 1911.
				</p>


				<p>
					<a href="http://www.boma.org" target="blank">BOMA International</a>
					BOMA is an organization of building owners and managers that promotes the real estate industry.
				</p>


				<p>
					<a href="http://www.cfma.org" target="blank">CFMA - Construction Financial Management Association</a>
					CFMA serves the information needs of construction financial professionals and service providers through publications, education programs and networking.
				</p>


				<p>
					<a href="http://www.crewnetwork.org" target="blank">CREW</a>
					CREW is dedicated to advancing the success of women in commercial real estate.
				</p>


				<p>
					<a href="http://www.dbia.org" target="blank">DBIA - Design Build Institute of America</a>
					DBIA was founded to advocate and advance single source project delivery within the design and construction communities.
				</p>


				<p>
					<a href="http://www.mcaa.org" target="blank">MCAA - Mechanical Contractors Association of America</a>
					MCAA services 2,300 firms involved in heating, air conditioning, refrigeration, plumbing, piping and mechanical services by providing educational materials and programs.
				</p>


				<p>
					<a href="http://www.nawic.org" target="blank">NAWIC - National Association of Women In Construction</a>
					NAWIC is dedicated to enhancing the success of women in the construction industry. NAWIC has 5,500 members and 179 chapters in almost every U.S. state.
				</p>


				<p>
					<a href="http://www.necanet.org" target="blank">NECA - National Electrical Contractors Association</a>
					NECA is the voice of the electrical contracting industry working to promote higher standards, quality workmanship and training.
				</p>


				<p>
					<a href="http://www.womencontractors.org" target="blank">WCA - Women Contractors Association</a>
					WCA is committed to the advancement of women in the construction arena through education, support and networking.
				</p>
			</div>
		);
	}
}

export default IndustryResourcesPage;