import React, {Component} from 'react';
import styled from 'styled-components';
import HeaderModule from "./HeaderModule";
import FooterModule from "./FooterModule";

const LayoutWrapper = styled.div`
	display:flex;
	flex-flow:column;
	height:100%;
`;

const BodyWrapper = styled.div`
	flex:1;
	font-size:0.8em;
	padding:1em 0;
	
	h1 {
		color: #333;
		font-size:1.25em;
	}
	
	@media screen and (max-width:70em) {
		padding:1em;
	}
`;


class LayoutModule extends Component {

	render() {

		// input

		// display
		return (
			<LayoutWrapper>
				<HeaderModule/>
				<BodyWrapper>{this.props.children}</BodyWrapper>
				<FooterModule/>
			</LayoutWrapper>
		);
	}
}

export default LayoutModule;