import React, {Component} from 'react';
import styled from 'styled-components';

const ImageScrollerWrapper = styled.div`
	margin-bottom:2em;

	& > div {
	
		&:first-child {
			max-width: 990px;
			overflow: hidden;
			display: flex;
			margin-bottom:0.5em;
			
			& > img {
				width:100%;
				height:43%;
				&:first-child { transition-duration: 500ms; }
			}
		}
		
		&:last-child {
			margin:0 -0.5em;
			& > button {
				width:98px;
				height:58px;
				border:none;
				background:none;
				outline:none;
				margin:0.5em;
				
				&:nth-child(1) { background-image:url(/img/home1-th.jpg); }
				&:nth-child(2) { background-image:url(/img/home2-th.jpg); }
				&:nth-child(3) { background-image:url(/img/home3-th.jpg); }
				&:nth-child(4) { background-image:url(/img/home4-th.jpg); }
				&:nth-child(5) { background-image:url(/img/home5-th.jpg); }
				&:nth-child(6) { background-image:url(/img/home6-th.jpg); }
				&:nth-child(7) { background-image:url(/img/home7-th.jpg); } 
			}
		}
	}
	
	@media screen and (max-width:23em) {
		display:none;
	}
`;

class ImageScrollerModule extends Component {

	constructor(props) {
		super(props);
		this.state = {
			imageIndex:0
		};
	}

	render() {
		
		// input
		const { imageIndex } = this.state;
	
		// display	
		return (
			<ImageScrollerWrapper>
				<div>
					<img src="/img/home1.jpg" alt="" style={{marginLeft:(-imageIndex*100)+"%"}}/>
					<img src="/img/home2.jpg" alt=""/>
					<img src="/img/home3.jpg" alt=""/>
					<img src="/img/home4.jpg" alt=""/>
					<img src="/img/home5.jpg" alt=""/>
					<img src="/img/home6.jpg" alt=""/>
					<img src="/img/home7.jpg" alt=""/>
				</div>
				<div>
					<button onClick={()=>{this.setState({imageIndex:0});}}/>
					<button onClick={()=>{this.setState({imageIndex:1});}}/>
					<button onClick={()=>{this.setState({imageIndex:2});}}/>
					<button onClick={()=>{this.setState({imageIndex:3});}}/>
					<button onClick={()=>{this.setState({imageIndex:4});}}/>
					<button onClick={()=>{this.setState({imageIndex:5});}}/>
					<button onClick={()=>{this.setState({imageIndex:6});}}/>
				</div>

			</ImageScrollerWrapper>
		);
	}
}

export default ImageScrollerModule;