import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NavLinksModule from "./NavLinksModule";

const HeaderWrapper = styled.div`

	& > section {
		display:flex;
		flex-flow: row wrap;
		align-items: center;
		
		& > a {
			max-width:100%;
			& > img { max-width:100%; }
			
			&:first-child > img {
				width:200px;
			}
			&:last-child > img {
				width:325px;
				max-width:100%:
			}
		}
		
		& > div {
			flex:1;
		}
		
		@media screen and (max-width:540px) {
			& > div { display:none; }
			& > a { margin:auto; }
		}
	}

	& > nav {
		
		& > a {
			text-transform:uppercase;
			font-size:0.9em;
			padding:0.75em 1em;
			display:inline-block;
			
			&:nth-child(n+2) {
				border-left:solid 2px var(--hover-color);
			}
			
			&:hover {
				color:#222;
				background:var(--hover-color);
			}
			
			@media screen and (max-width:40em) {
				& > span {
					display:none;
				}
			}
		}
		
		@media screen and (max-width:50em) {
			font-size:0.8em;
		}
		
		@media screen and (max-width:33.7499999em) {
			& > a {
				display:block;
				border-left:none !important;
				& > span {
					display:inline;
				}
			}
		}
	}
`;


class HeaderModule extends Component {

	render() {
		
		// input
	
		// display	
		return (
			<HeaderWrapper>
				<section>
					<Link to="/"><img src="/img/logo.gif" alt="Conexco" /></Link>
					<div/>
					<Link to="/"><img src="/img/desc.gif" alt="Conexco" /></Link>
				</section>
				<NavLinksModule/>
			</HeaderWrapper>
		);
	}
}

export default HeaderModule;