import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavLinksWrapper = styled.nav`

	color:White;
	background:var(--primary-color);
	padding:2px;
	
	& > a {
		color:inherit;
		text-decoration:none;
	}
`;

class NavLinksModule extends Component {

	render() {
		
		// input
		const onClick = this.props.gotoTop ? ()=>{window.scrollTo(0,0)} : undefined;
	
		// display	
		return (

			<NavLinksWrapper>
				<Link to="/" onClick={onClick}>Home</Link>
				<Link to="/about" onClick={onClick}>About<span> Us</span></Link>
				<Link to="/process"onClick={onClick}>Process</Link>
				<Link to="/resume_submittal" onClick={onClick}>Resume Submittal</Link>
				<Link to="/industry_resources" onClick={onClick}><span>Industry </span>Resources</Link>
				<Link to="/contact_us" onClick={onClick}>Contact<span> Us</span></Link>
			</NavLinksWrapper>
		);
	}
}

export default NavLinksModule;