import styled from 'styled-components';

const HeaderImageWrapper = styled.div`
	background:url(/img/headers.jpg) center center;
	width:100%;
	height:125px;
	margin-bottom:2em;
	
	&.preload { display:none; }
	&.about { background-position-y:0; }
	&.process { background-position-y:-125px; }
	&.resume { background-position-y:-250px; }
	&.resources { background-position-y:-375px; }
	&.contact { background-position-y:-500px; }
`;

export default HeaderImageWrapper;