import React, {Component} from 'react';
import HeaderImageWrapper from "../modules/HeaderImageWrapper";

class ContactUsPage extends Component {

	constructor(props) {
		super(props);
		document.title = "Contact Us - Conexco Group, LLC";
	}

	render() {
		
		// input
	
		// display	
		return (
			<div>
				<HeaderImageWrapper className="contact"/>

				<h1>CONTACT US</h1>

				<address>
					CONEXCO Group LLC<br />
					6614 Clayton Road<br />
					Suite 307<br />
					St. Louis, MO. 63117<br />
				</address>

				<p>
					Telephone:<br />
					<a href="tel:3148623777">314 862 3777</a>
				</p>

				<p>
					Fax:<br />
					<a href="fax:3143710034">314 371 0034</a>
				</p>

				<p>
					Daniel J. Conroy<br />
					Managing Principal<br />
					<a href="tel:3148623777,105">314 862 3777 Ext.105</a>
					<a href="mailto:danc@conexcogroup.com">danc@conexcogroup.com</a>
				</p>

				<p>
					Daniel P. Conroy<br />
					Vice President<br />
					<a href="tel:3148623777,107">314 862 3777 Ext.107</a>
					<a href="mailto:dpc@conexcogroup.com">dpc@conexcogroup.com</a>
				</p>
			</div>
		);
	}
}

export default ContactUsPage;